@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000424;
}

iframe {
  background: #000424;
}

span.red {
  color: #FF006B;
}

span.blue {
  color: #00FFF2;
}

h1 {
  margin: 20px;
}
